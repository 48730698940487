module directives {
    export module master {
        interface IFileTemplateUploadAuditLogsScope extends ng.IScope {
            referenceId: number;
            transactionTypeClassificationValueId: number;
            showFileTemplateUploadAuditLogs():void;
        }

        export class fileTemplateUploadAuditLogsDirective implements ng.IDirective
        {
            template = ` 
                            <button type="button"  class="btn btn-info" ng-click="showFileTemplateUploadAuditLogs()" ng-disabled="ngDisabled">
                             <i class="fa fa-info-circle"></i>
                            </button>
                        `;
            scope = {
                referenceId: "=",
                transactionTypeClassificationValueId: "=",
                ngDisabled: "="
            }
            
           fileTemplateUploadAuditLogs: interfaces.master.FileTemplateUploadAuditLog[];

            constructor(private generalService: interfaces.applicationcore.IGeneralService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private fileTemplateService: interfaces.master.IFileTemplateService,
                public $uibModal: ng.ui.bootstrap.IModalService,
                public bsLoadingOverlayService,
            ) {
            }

            link = ($scope: IFileTemplateUploadAuditLogsScope, $element: ng.IAugmentedJQuery) =>
            {
                $scope.showFileTemplateUploadAuditLogs = () => {
                    //Get the address.
                    this.fileTemplateService.getFileTemplateUploadAuditLogs($scope.referenceId, $scope.transactionTypeClassificationValueId).query({
                    }, (result: interfaces.master.FileTemplateUploadAuditLog[]) => {
                            this.fileTemplateUploadAuditLogs = result
                            this.showAuditLogs(this.fileTemplateUploadAuditLogs);
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                }
            }

            //Display Addresses
            showAuditLogs(fileTemplateUploadAuditLogs: interfaces.master.FileTemplateUploadAuditLog[]) {
                this.$uibModal.open({
                    animation: true,
                    template: `
                    <div class="modal-header">
                        <strong class="modal-title">File Upload History</strong>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid row">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>File Name</th>
                                        <th>Date and Time</th>
                                        <th>Uploaded By</th>
                                        <th>Successful</th>                                        
                                        <th>Messages</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr ng-repeat="fileTemplateUploadAuditLog in fileTemplateUploadAuditLogs">                                      
                                        <td>
                                            <a style="cursor:pointer" ng-click="GetFileTemplateUploadAuditLogFile(fileTemplateUploadAuditLog.Id)">{{fileTemplateUploadAuditLog.FileName}}</a>
                                        </td>
                                        <td>{{fileTemplateUploadAuditLog.CreateStampDate | momentDateFilter: 'YYYY/MM/DD hh:mm:ss'}}</td>
                                        <td>{{fileTemplateUploadAuditLog.CreateStampFullName}}</td>                                       
                                        <td>
                                            <span ng-if="fileTemplateUploadAuditLog.IsSuccessful">Yes</span>
                                            <span ng-if="!fileTemplateUploadAuditLog.IsSuccessful">No</span>
                                        </td>
                                        <td>{{fileTemplateUploadAuditLog.ErrorMessages}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                                    <button class="btn btn-primary" type="button" ng-click="ok()">OK</button>
                                </div>
                    `,
                    controller: ($scope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) => {

                        $scope.ok = () => {
                            $uibModalInstance.dismiss();
                        }

                        $scope.fileTemplateUploadAuditLogs = this.fileTemplateUploadAuditLogs;

                        $scope.submit = () => {
                           // this.submit();
                            $scope.ok();
                        }

                        $scope.GetFileTemplateUploadAuditLogFile = (id:number) => {
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'fileTemplate.AuditLog'
                            },
                                () => {
                                    this.fileTemplateService.getFileTemplateUploadAuditLogFileById(id).then(() => {
                                    }, (data) => {
                                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                                    });
                                });
                        }
                    },
                    size: "lg",
                    resolve: {
                    }
                });
            }
   
            static factory(): ng.IDirectiveFactory {
                const directive = (generalService, classificationValueService, fileTemplateService, countryService, $uibModal,bsLoadingOverlayService) => new fileTemplateUploadAuditLogsDirective(generalService, classificationValueService, fileTemplateService, $uibModal,bsLoadingOverlayService);
                directive.$inject = ["generalService", "classificationValueService",  "fileTemplateService", "countryService", "$uibModal","bsLoadingOverlayService"];

                return directive;
            }
        }

        angular.module("app").directive("gtsFileTemplateUploadAuditLogPopup", fileTemplateUploadAuditLogsDirective.factory());
    }
}